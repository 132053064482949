@import "../../App/App.scss";

// ANIMATION FOR THE NOTIFICATION BELL

@keyframes anim-glow {
	0% {
		box-shadow: 0 0 rgba($contrast-color, 1);
	}
	100% {
		box-shadow: 0 0 10px 8px transparent;
		border-width: 2px;
	}
}

.nav-notification-icon {
	background-color: $contrast-color;
	border-radius: 50%;
	border: 2px solid $contrast-color;
	width: 10px;
	height: 10px;
	text-align: center;
	position: absolute;
	top: 0.35rem;
	left: 0.9rem;
	animation: anim-glow 2s ease infinite;
	animation-iteration-count: 3;
	i {
		border-radius: 50%;
		border: 2px solid $contrast-color;
		width: 2px;
		height: 2px;
		display: inline-block;
		vertical-align: 6px;
		background-color: $contrast-color;
	}
}

