@import "../../App/App.scss";

.stepper-container {
	width: 100%;
	position: absolute;
	z-index: 1;
}

.progressbar {
	counter-reset: step;
}

.progressbar li {
	float: left;
	width: 25%;
	position: relative;
	text-align: center;
	list-style-type: none;
}

.progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 35px;
	height: 35px;
	line-height: 30px;
	border: 2px solid darken($color: $light-color, $amount: 10);
	display: block;
	margin: 0 auto 10px auto;
	border-radius: 50%;
	background: white;
	color: #bebebe;
	text-align: center;
	font-family: $title-font;
}

.progressbar li:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 3px;
	background: darken($color: $light-color, $amount: 10);
	top: 15px;
	left: -50%;
	z-index: -1;
}

.progressbar li:first-child:after {
	content: none;
}

.progressbar li.checked-step + li:after {
	background: $secondary-color;
}

.progressbar li.checked-step {
	color: $secondary-color;
}

.progressbar li.checked-step:before {
	border-color: $secondary-color;
	background-color: $secondary-color;
	color: $white;
}

.progressbar li.active-step:before {
	border-color: $secondary-color;
	color: $secondary-color;
}
