@import "../../App/App.scss";

.moderation-card {
	padding: 1rem 0.5rem;
	margin: 0.5rem 0;
	background-color: $white;
}

.avatar-moderation-card {
	background-color: $light-color;
	border-radius: 7px;
	padding: 1rem;
	margin: 0.75rem 0;
	width: 220px;
	height: auto;
}

.avatar-moderation-card-body {
	margin: 0 auto;
	p {
		margin: 0;
	}
}

.moderation-icon {
	width: 1.7rem;
	height: auto;
	margin: 0 auto;
	vertical-align: middle;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.senior-avatar {
	margin: 0 auto;
	width: 130px;
	height: auto;
	border-radius: 50%;
}

.moderation-card-footer {
	padding-top: 0.25rem;
	margin: 0 auto;
}

.moderation-card-icon-ok {
	width: 1.5rem;
	height: auto;
	margin: 0;
	color: $secondary-color;
	cursor: pointer;
}

.moderation-card-icon-ok:hover {
	color: $primary-color;
}

.moderation-card-icon-no {
	width: 1.5rem;
	height: auto;
	margin: 0 0.3rem 0 0;
	color: $primary-color;
	cursor: pointer;
}

.moderation-card-icon-no:hover {
	color: $contrast-color;
}

.moderation-header-details {
	background-color: $light-color;
	margin: 0;
	padding: 0.5rem 0;
	p {
		margin: 0;
		padding: 0;
	}
}

.moderation-body-details {
	border-bottom: solid 2px darken($color: $light-color, $amount: 5);
	margin: 0;
	padding: 1rem 0;
}

.img-news-moderation {
	width: auto;
	height: 9rem;
	margin: 0 0.25rem 0.25rem 0;
}

.senior-photobox {
	width: auto;
	height: 12rem;
}

.header-photobox {
	width: 12rem;
	display: block;
	background-color: $light-color;
	margin: 0.5rem;
	padding: 1rem;
	height: 12rem;
	font-size: 1rem;
}

.news-photocounter {
	width: 8rem;
	display: block;
	background-color: $light-color;
	margin: 0.25rem;
	padding: 2rem 1rem;
	height: 12rem;
	position: relative;
}

.news-photocounter-text {
	position: absolute;
	bottom: 1rem;
	color: darken($color: $light-color, $amount: 22);
	font-size: 2.5rem;
	font-family: $title-font;
}

.moderation-video-container {
	display: inline-block;
	width: auto;
	height: 100%;
	max-height: 10rem;
}
