body {
	font-family: "Arial Nova Light", sans-serif;
	margin: 0;
	padding: 0;
	background-color: white;
}
.container {
	width: 210mm;
	margin: auto;
	padding: 20px;
}
.header img {
	float: left;
}
.header .amd-logo {
	float: right;
}
.header .address {
	clear: both;
	font-size: 15px;
	margin-left: 5px;
	margin-bottom: 40px;
}
.header .address p {
	margin: 0;
	font-weight: bold;
}
.header::after {
	content: "";
	display: table;
	clear: both;
}
.header .rectangle-header {
	background-color: #4472c4;
	color: white;
	padding: 12px 0;
	margin: 20px auto 0;
	text-align: center;
	width: 90%;
	font-size: 20px;
}

.chart {
	text-align: center;
	margin: 20px 0;
}
.stats {
	display: flex;
	justify-content: space-between;
}
.stats div {
	width: 48%;
}
table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 10px;
}
table,
th,
td {
	border-left: none;
}
th,
td {
	padding: 2px;
	font-size: 15px;
}

.styled-table {
	width: 50%;
	border-collapse: collapse;
	margin: 0 auto;
}

.styled-table td {
	font-size: 15px;
	border: none;
}

.incontinence {
	background-color: #4472c4;
	color: white;
	padding: 4px;
}
.padding {
	padding: 8px;
}
.marginTop {
	margin-top: 20px;
}
.margin-Other-report {
	margin-bottom: 0;
	margin-top: -10px;
}

.tableAvg {
	margin: 5px auto 0 auto;
	width: 50%;
	border-collapse: collapse;
}
.tableAvg td {
	padding: 1;
	font-size: 15px;
}
.daily-avrg {
	background-color: #4472c4;
	color: white;
	margin-top: 140px;
	text-align: right;
}

.equipped {
	background-color: #d9e2f3;
	color: black;
	font-weight: bold;
	padding: 4px;
}

.incontinence-count {
	text-align: right;
	background-color: #4472c4;
	color: #fff;
	font-weight: bold;
	padding: 4px;
}
.equipped-count {
	text-align: right;
	background-color: #d9e2f3;
	font-weight: bold;
	padding: 4px;
}
.centered-text {
	text-align: center;
	font-size: 15px;
	font-weight: bold;
}
.full-width-line {
	border: none;
	border-top: 2px solid black;
	width: 100%;
}
.half-width-line {
	border: none;
	border-top: 1px solid black;
	width: 20%;
	margin: 10px 0;
}
.chart-container {
	position: relative;
	width: 100%;
	max-width: 400px;
	margin-left: 50px;
}

.horizontal-legend .chartjs-legend li {
	display: flex;
	align-items: center;
	margin-right: 10px;
}
.most-changed {
	background-color: #4472c4;
	color: #fff;
	border: none;
	padding: 4px;
}
.colored-tr-change {
	background-color: #d9e2f3;
	color: #000;
	margin: 0;
}

.high-confirmity {
	background-color: #70ad47;
	color: #fff;
}

.high-confirmity-th {
	background-color: #70ad47;
	color: #fff;
	padding: 4px;
	width: 100%;
}

.high-confirmity-tr {
	background-color: #e2efd9;
	color: #000;
	white-space: nowrap;
	word-break: keep-all;
}
.low-confirmity {
	background-color: #ed7d31;
	color: #fff;
	padding: 4px;
}
.low-confirmity-tr {
	background-color: #fbe4d5;
	color: #000;
	margin: 0;
}
.bold {
	font-weight: bold;
}

.section-dot {
	margin-bottom: 10px;
	background-color: #f6f9fd;
}

.section-change-stats .recharts-default-legend {
	width: 700px !important;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	text-align: center;
	justify-content: center;
	margin-left: -30px !important;
}

.section-content {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
}
.data-box {
	flex: 1;
	padding: 10px;
	background-color: #fff;
	text-align: center;
	border-right: 2px solid #e7eaed;
}

.pie-box {
	flex: 1;
	padding: 10px;
	border-radius: 4px;
	background-color: #fff;
	text-align: center;
}
.data-box:last-child {
	margin-right: 0;
}
.data-box .value {
	font-size: 24px;
	font-weight: bold;
	margin: 10px 0;
}
.data-box .unit {
	font-size: 10px;
	color: #777;
}
.data-box .objective {
	font-size: 12px;
	color: #7ad599;
	text-align: left;
	font-weight: bold;
	position: relative;
	margin-bottom: 8px;
}

.data-box .objective::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #7ad599;
	border-radius: 50%;
	margin-right: 5px;
	vertical-align: middle;
}
.sync-date {
	text-align: right;
	font-size: 12px;
	color: #555;
}

.details-item {
	flex: 1;
	padding: 10px;
	text-align: left;
}
.details-item p {
	margin: 5px 0;
	color: #9ca8b4;
}
.details-item .label {
	font-weight: bold;
}
.details-item .number {
	font-size: 24px;
	color: #007bff;
}
.change-stats {
	background-color: #fff;
	color: #333;
	max-width: 1000px;
	margin-left: 120px;
	margin-right: -80px;
}
.dotation-stats {
	background-color: #f7f9fc;
	color: #333;
	max-width: 900px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin-right: 0;
	margin-left: 10px;
	padding: 10px;
}
.unit-1 {
	font-size: 10px;
	color: #777;
	text-align: left;
	font-weight: bold;
}
.label-1 {
	font-size: 10px;
	color: #777;
	font-weight: bold;
}

.nums {
	font-size: 12px;
	color: #46b9e9;
	font-weight: bold;
	text-align: right;
	float: right;
	margin-top: 1px;
	margin-left: 20px;
	margin-right: -20px;
}

.data-box2 {
	flex: 0.5;
	padding: 20px;
	border-radius: 4px;
	background-color: #fff;
	padding-right: 24px;
	height: 90%;
	margin-right: 5px;
}
.data-box2:last-child {
	margin-right: 0;
}
.data-box2 .value {
	font-size: 24px;
	font-weight: bold;
	margin: 10px 0;
}
.dotation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}
.dotation .icon {
	margin-right: 10px;
}

.dotation .text {
	flex-grow: 1;
}
.small-title {
	font-size: 12px;
	color: #000;
	font-weight: bold;
}

.label2 {
	font-size: 10px;
	color: #46b9e9;
	font-weight: bold;
	text-align: left;
	list-style: none;
	padding: 3px;
}
.label2::before {
	content: "\276F";
	color: #46b9e9;
	margin-right: 10px;
}

.wback {
	background-color: #fff;
	color: #000;
	margin: 0;
	white-space: nowrap;
	word-break: keep-all;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}
.footer1 {
	text-align: right;
	font-size: 8px;
	margin-top: 77px;
}
.footer2 {
	text-align: right;
	font-size: 8px;
	margin-top: 18px;
	margin-right: -50px;
}
.footer3 {
	text-align: right;
	font-size: 8px;
	margin-top: 560px;
}

span.recharts-legend-item-text {
	font-size: 14px;
}
.font-size {
	font-size: 10px;
}

@media print {
	.landscape {
		page: landscape-page;
	}

	@page landscape-page {
		size: landscape;
	}
}
