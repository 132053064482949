@import "../../App/App.scss";

.admin-form {
	max-width: 600px;
}

.beauty {
	background-color: darken($color: #eaac9d, $amount: 3);
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.hydratation {
	background-color: darken($color: $light-color, $amount: 28);
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.nutrition {
	background-color: #fd7f2c;
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.activity {
	background-color: #de1761;
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.care {
	background-color: #b97cfc;
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.hygiene {
	background-color: #10e0e0;
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.visit {
	background-color: #ffc440;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.emergency {
	background-color: #ff0000;
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.medecine {
	background-color: darken($color: #03a89e, $amount: 5);
	color: white;
	padding: 0 0.5rem;
	border-radius: 8px;
}

.mood {
	background-color: darken($color: $light-color, $amount: 4);
	padding: 0 0.5rem;
	border-radius: 8px;
}
