@import "../../App/App.scss";

.header-panel {
	position: fixed;
	color: white;
	width: 100%;
	margin: 0;
	padding: 0;
	height: 3.5rem;
	top: 0;
	left: 0;
	z-index: 99;
	background: $primary-color;
	background: linear-gradient(166deg, rgba(2, 0, 36, 1) 0%, rgba(3, 34, 76, 1) 30%, rgba(0, 160, 227, 1) 100%);
}

.footer-panel {
	position: fixed;
	width: 100%;
	bottom: 0;
	background-color: white;
}

.white-logo {
	height: 100%;
	max-height: 35px;
	width: auto;
}

.amd-white-logo {
	height: auto;
	width: 6.6rem;
	margin: 0.1rem 0 0 0;
}

.control-panel-icon {
	color: $white;
	height: 24px;
	width: auto;
	margin: 16px 0 0 24px;
}

.panel-container {
	margin: 4.2rem 0.4rem 0.4rem 0.4rem;
	min-height: 82vh;
}

.panel-gradient-card {
	margin: 0;
	padding: 0.5rem;
	width: 100%;
	font-family: $text-font;
	@include fluid-type(0.8rem, 0.85rem);
	letter-spacing: 0.3px;
	background-color: $white;
	cursor: pointer;
	border-radius: 0.5rem;

	.panel-icon {
		height: 1.5rem;
		width: auto;
		margin: 0 0.2rem 0.5rem 0.5rem;
	}

	.panel-number {
		@include fluid-type(1.4rem, 1.5rem);
		font-family: $title-font;
	}

	.panel-meta {
		@include fluid-type(0.9rem, 0.95rem);
		font-family: $text-font;
	}

	&.grey {
		border: solid 1px gainsboro;

		.panel-icon {
			color: gainsboro;
		}
		.panel-number {
			color: gainsboro;
		}
	}

	&.blue {
		border: solid 1px $secondary-color;

		.panel-icon {
			color: $secondary-color;
		}
		.panel-number {
			color: $secondary-color;
		}
	}
	&.red {
		border: solid 1px $contrast-color;

		.panel-icon {
			color: $contrast-color;
		}
		.panel-number {
			color: $contrast-color;
		}
	}
}

.panel-table {
	font-family: $text-font;
	@include fluid-type(0.85rem, 0.9rem);
	letter-spacing: 0.25px;
}

.panel-counter {
	@include fluid-type(1rem, 1.05rem);
	font-family: $title-font;
}

.floating-button {
	position: absolute;
	width: 50px;
	height: 50px;
	bottom: 60px;
	right: 60px;
	background-color: $secondary-color;
	border-radius: 50px;
	text-align: center;
	box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
	border: none;
	z-index: 9;
}

.floating-button:hover {
	background-color: $primary-color;
}

.floating-button:focus {
	outline: 0;
}

.floating-icon {
	color: $white;
	width: 30px;
	height: auto;
}

.panel-notification-ok,
.panel-notification-no,
.panel-notification-res {
	width: 36px;
	height: auto;
	cursor: pointer;
	margin: 0.5rem;
}

.panel-notification-ok:hover,
.panel-notification-no:hover,
.panel-notification-res:hover {
	color: $primary-color;
}
.blog-title {
	font-family: $title-font;
	color: $secondary-color;
	@include fluid-type(1.1rem, 1.15rem);
	padding: 0.5rem 0;
	letter-spacing: 0.2px;
	cursor: pointer;
	border: none;
}

.blog-underline {
	border-top: solid 1px lighten($color: $grey, $amount: 15);
	width: 50%;
	margin-bottom: 1rem;
}

.social-card {
	padding: 0;
	border-radius: 20px;
	border: solid 2px darken($color: $light-color, $amount: 4);
	margin: 1.5rem 0;

	h5 {
		margin: 0.5rem 0 0 1rem;
		@include fluid-type(1.4rem, 1.5rem);
		color: $secondary-color;
	}

	.header-social-card {
		padding: 1rem;
	}

	.body-social-card {
		padding: 1rem;
	}

	.footer-social-card {
		border-top: solid 2px darken($color: $light-color, $amount: 4);
		padding: 1.5rem 1rem;
	}
}

.panel-like-button {
	color: $contrast-color;
	@include fluid-type(0.8rem, 0.85rem);
	cursor: pointer;
	vertical-align: middle;
	width: 1.5rem;
	height: auto;
}

.panel-like-button:hover {
	color: $secondary-color;
}

.react-select__multi-value {
	font-size: 0.95rem;
	vertical-align: middle;
	letter-spacing: 0.25px;
}

.react-select__menu-list {
	font-size: 0.9rem;
	line-height: 1.75rem;
}

.calendar-card {
	background-color: $white;
	padding: 0.25rem 0 0.5rem 1rem;
	margin: 0 0 0.5rem 0;
	&.visio {
		border-left: solid 4px $contrast-color;

		.type {
			text-transform: uppercase;
			@include fluid-type(0.7rem, 0.75rem);
			color: $contrast-color;
			font-family: $title-font;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			font-family: $title-font;
			margin: 0.25rem 0;
		}
	}
	&.event {
		border-left: solid 4px $blue;
		.type {
			text-transform: uppercase;
			@include fluid-type(0.7rem, 0.75rem);
			color: $secondary-color;
			font-family: $title-font;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			font-family: $title-font;
			margin: 0.25rem 0;
		}
	}
	&.alreadyDone {
		border-left: solid 4px $grey;
		.type {
			text-transform: uppercase;
			@include fluid-type(0.7rem, 0.75rem);
			color: $grey;
			font-family: $title-font;
			letter-spacing: 0.5px;
			margin: 0;
			padding: 0;
		}
		.info {
			margin: 0.25rem 0;
			color: $grey;
		}
	}
}

.visio-tag {
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
	border-radius: 7px;
	@include fluid-type(0.78rem, 0.8rem);
	color: $white !important;
	font-family: $title-font;
	border: none;

	&.red {
		background-color: $contrast-color;
	}
	&.grey {
		background-color: lighten($color: $grey, $amount: 20);
	}
}
