@import "../../../App/App.scss";

.document-thumb {
	height: 100px;
	width: auto;
	margin: 0.75rem;
	padding: 0;
}

.document-container {
	padding: 0;
	margin: 0;
	height: 190px;
	width: auto;
	max-width: 220px;
}

.document-border {
	padding: 1rem;
	margin: 1rem 1rem 1rem 0;
	border-radius: 7px;
	border: solid 1px lighten($color: $grey, $amount: 40);
	position: relative;
}

.suppr-btn {
	color: $contrast-color;
	width: auto;
	height: 1.25rem;
	position: absolute;
	top: 0;
	left: 50%;
	right: 50%;
	transform: translate(-50%, -50%);
	border: none;
	padding: 0;
	margin: 0;
	background-color: white;
}

.suppr-btn:hover {
	color: $secondary-color;
	border: none;
	background-color: white;
}
