@import "./App.scss";

@media print {
	header,
	.my-main-nav,
	.admin-main-nav,
	.my-secondary-nav,
	.dont-print-this {
		display: none;
	}

	.big-card {
		border: 1px solid $light-color;
	}

	a {
		page-break-inside: avoid;
	}
	blockquote {
		page-break-inside: avoid;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}
	table,
	pre {
		page-break-inside: avoid;
	}
	ul,
	ol,
	dl {
		page-break-before: avoid;
	}
	a {
		page-break-inside: avoid;
	}
}
